<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.CASH_REGISTER')}`"
      :placeholder="$t('COMMON.CASH_REGISTER')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_CASH_REGISTERS) &&
        !hideOrganization
      "
    >
      <cash-register-selector
        :filterable="true"
        :showAll="false"
        @cashRegisterChanged="
          (cashRegisterId, cashRegister) => {
            cashRegisterReading.cashRegister.id = cashRegisterId;
            cashRegisterReading.organization.id = cashRegister.organization.id;
            cashRegisterReading.establishment = cashRegister.establishment;
            cashRegisterReading.serviceCenter = cashRegister.serviceCenter;
            cashRegisterReading.cafeteria = cashRegister.cafeteria;
            onFormChanged();
          }
        "
        :disabled="!!cashRegisterReading.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cashRegister" />

    <base-input
      :label="`${$t('CASH_REGISTER_READINGS.NUMBER_Z')} (*)`"
      :required="true"
      :placeholder="$t('CASH_REGISTER_READINGS.NUMBER_Z')"
      v-model="cashRegisterReading.number_z"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.cashier" />
    <base-input
      :label="`${$t('CASH_REGISTER_READINGS.CHECKOUT_PERSON')} (*)`"
      :required="true"
      :placeholder="`${$t('CASH_REGISTER_READINGS.CHECKOUT_PERSON')}`"
      v-model="cashRegisterReading.cashier"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.cashier" />
    <base-input
      :label="`${$t('CASH_REGISTER_READINGS.STARTS_AT')} (*)`"
      :placeholder="$t('CASH_REGISTER_READINGS.STARTS_AT')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          allowInput: true,
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          // minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="cashRegisterReading.starts_at"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.starts_at" />
    <base-input
      :label="`${$t('CASH_REGISTER_READINGS.ENDS_AT')} (*)`"
      :placeholder="$t('CASH_REGISTER_READINGS.ENDS_AT')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          // minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="cashRegisterReading.ends_at"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.ends_at" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          cashRegisterReading.id
            ? $t("CASH_REGISTER_READINGS.EDIT_CASH_REGISTER_READING")
            : $t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CashRegisterSelector from "@/components/CashRegisterSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    flatPicker,
    OrganizationSelector,
    CashRegisterSelector,
  },

  mixins: [formMixin],

  props: [
    "cashRegisterReadingData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let cashRegisterReadingData = { ...this.cashRegisterReadingData };
    cashRegisterReadingData = this.$fillUserOrganizationData(
      cashRegisterReadingData
    );
    return {
      cashRegisterReading: cashRegisterReadingData,
      renderKey: 0,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let cashRegisterReadingData = cloneDeep(this.cashRegisterReading);
      cashRegisterReadingData = this.$fillUserOrganizationData(
        cashRegisterReadingData
      );
      this.$emit("cashRegisterReadingSubmitted", cashRegisterReadingData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    cashRegisterReadingData(cashRegisterReadingData) {
      if (cashRegisterReadingData) {
        this.cashRegisterReading = {
          ...this.cashRegisterReading,
          ...cloneDeep(cashRegisterReadingData),
        };
      }
    },
  },
};
</script>
